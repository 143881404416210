@import "../colors";
@import "../base";

%input {
	background-color: $background-light-2;
}

%input-search {
	@extend %input;
}

input,
select {
	background-repeat: no-repeat;
}

input.cihtInputField,
select.cihtDropdown {
	padding: calc(1rem + 2px) 4rem calc(1rem - 2px) 1rem; //font hack
	font-size: 1rem;
	font-family: "effra-regular";
	color: #999999;
	background-color: $color-bgrey;
	border: 3px solid $color-grey;
	appearance: none;
	background-position: calc(100% - 1rem) center;
	&.teal {
		color: $color-teal;
		border-color: $color-teal;
	}

	option {
		color: $color-dblue;
	}
}

.cihtInputField {
	border-color: #bfe6e9;
	height: 50px;
	flex-grow: 1;
	max-width: unset;
	@include phone-landscape {
		max-width: 320px;
	}
	&.dx-texteditor {
		line-height: 2.25;
		padding: 0;
	}
}

input[role="search"],
input[type="search"] {
	background-image: url("../../assets/search.svg");
	min-width: 320px;
}

select.cihtDropdown {
	color: $color-dblue;
	flex-grow: 1;
	font-weight: bold;
	min-width: 140px;
	background-image: url("../../assets/chevron-down.svg");
	background-color: white;
	border-width: 1px;
	cursor: pointer;
	&:first-child {
		margin-bottom: 0;
	}
	&:last-child {
		margin-bottom: 0;
		margin-right: 0;
	}
	@include tablet-landscape {
		max-width: 320px;
	}

	&.teal {
		background-image: url("../../assets/chevron-down-teal.svg");
	}
}
.ciht-form {
	input,
	select,
	textarea {
		font-size: 1.125rem;
		font-family: "effra-regular";
	}
	.field {
		display: flex;
		flex-direction: column;
		margin-bottom: 3rem;
		padding-bottom: 0.5rem;

		label {
			font-size: 1.125rem;
			font-weight: bold;
			margin-bottom: 1rem;
		}
		input,
		textarea,
		select {
			font-size: 1.125rem;
			/*margin-right: 3rem;*/
			padding: 1rem;
			font-family: "effra-regular";
			border: 1px solid #5394c8;
			border-top-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}
		input,
		select {
			padding-bottom: calc(1rem - 6px);
		}
		textarea {
			width: 100%;
		}
		select {
			@extend .cihtDropdown;
			max-width: unset;
			color: #828282;
			font-weight: normal;
		}
		.datePick {
			background-image: url("../../assets/icoCalendar.svg");
			background-size: 1rem;
			background-position: calc(100% - 1rem) center;
			cursor: pointer;
		}
	}
}
/*--------------------------*/

input.cihtRadio,
input.cihtRadio:checked {
	appearance: none;
	border: none;
}
input {
	&.cihtRadio {
		&:checked {
			&:before {
				border-radius: 50%;
				border: 2px solid red;
				background-color: red;
			}
		}
		&:not(:checked) {
			& + label {
				&:after {
					opacity: 0;
					-webkit-transform: scale(0);
					transform: scale(0);
				}
			}
		}
		&:not(disabled):checked {
			& + label {
				&:after {
					opacity: 1;
					-webkit-transform: scale(1);
					transform: scale(1);
				}
			}
		}
	}
	&.cihtChkBox {
		appearance: none;
		-webkit-appearance: none;
		cursor: pointer;
		background-color: white;
		padding: 4px;
		border: 1px solid #5394c8;
		position: relative;
		width: 18px;
		height: 18px;
		margin-left: 0;
		@include phone-portrait {
			margin-left: 2rem;
		}
		& + label {
			font-size: 1rem;
			color: #292522;
			font-weight: normal;
			margin-left: 0.5rem;
			margin-bottom: -4px; //font hack
		}
		&:checked {
			/*background-color: #0F3250;*/
			&:before {
				content: "";
				width: 10px;
				height: 6px;
				border-bottom: 2px solid $color-dblue;
				border-left: 2px solid $color-dblue;
				color: white;
				position: absolute;
				top: 4px;
				left: 3px;
				transform: rotate(-55deg);
			}
		}
		&:focus {
			outline: none;
		}
	}
}
input.cihtRadio:checked + label,
input.cihtRadio:not(:checked) + label {
	position: relative;
	padding-left: 28px;
	margin: 0 1em;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
}
input.cihtRadio:checked + label:before,
input.cihtRadio:not(:checked) + label:before {
	content: "";
	position: absolute;
	left: -2px;
	top: -2px;
	width: 20px;
	height: 20px;
	border: 2px solid #0f3250;
	border-radius: 100%;
	background: #fff;
}
input.cihtRadio:checked + label:after,
input.cihtRadio:not(:checked) + label:after {
	content: "";
	width: 12px;
	height: 12px;
	background: #0f3250;
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

input {
	&.cihtRadio {
		&:not(:checked) {
			& + label {
				color: red;
				opacity: 0;
				-webkit-transform: scale(0);
				transform: scale(0);
			}
		}
	}
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}
