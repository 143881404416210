@import "src/scss/colors";
@import "src/scss/base";
@import "src/scss/elements/button";

.wrapper {
	display: flex;
	position: sticky;
	top: 0;
	background: white;
	width: 100%;
	z-index: 109;
	box-shadow: 0 0 1px grey;
	height: auto;
	@include phone-landscape {
		height: 72px;
	}
	&.shadow {
		@include tablet-landscape {
			box-shadow: 0px 2px 5px grey;
		}
	}
}

.topHeader {
	align-items: flex-start;
	position: relative;
	max-width: 1356px;
	margin: auto;
	display: flex;
	flex: 1;
	flex-direction: column;
	//justify-content: space-between;
	padding: 0 20px;
	//padding-top: 0.5rem;
	padding-bottom: 0;
	@include phone-landscape {
		align-items: center;
		flex-direction: row;
	}

	.logo {
		max-width: 140px;
		display: flex;
		align-items: center;
		margin-right: 2rem;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	a {
		color: $color-menu-blue;
	}
	.mainMenu {
		align-self: flex-end;
		padding-bottom: 0.5rem;
		margin-left: 0.5rem;
	}
	nav {
		display: flex;
		align-self: center;
		margin-left: 0.5rem;
		margin-right: 0.5rem;

		.menuContainer {
			position: absolute;
			flex-direction: column;
			gap: 1rem;
			width: 100%;
			/*display: flex;*/
			display: none;
			margin-top: 2rem;
			padding-bottom: 4rem;
			padding-top: 1rem;
			background: white;
			right: 0;
			//border-bottom-right-radius: 60px;
			z-index: 99;
			@include tablet-landscape {
				display: flex;
				position: static;
				flex-direction: row;
				margin-top: 0;
				padding-top: 0;
				padding-bottom: 0;
				//border-bottom-right-radius: 60px;
			}
			@include desktop {
				gap: 2rem;
			}
		}

		a svg {
			opacity: 0.25;
		}

		.hamburgerIcon {
			display: block;
			background: $color-dblue;
			color: white;
			width: 30px;
			height: 30px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			@include tablet-landscape {
				display: none;
			}
			.sideNav {
				position: absolute;
				top: 30px;
				background: $color-grey;
				padding: 1rem;
			}
		}

		.menuItem {
			color: $color-dblue;
			position: relative;
			display: inline;
			border-bottom: 1px solid $color-teal;
			letter-spacing: 0.25px;
			padding-top: 1rem;
			padding-bottom: 0;
			font-weight: 600;
			@include tablet-landscape {
				display: block;
				border: none;
				padding-top: calc(0.75rem + 7px);
				padding-bottom: 0;
			}

			&:last-child a {
				padding-right: 0.5rem;
			}

			//more hitArea to the left?
			a {
				width: 101%; //safari
				display: flex;
				flex-direction: column;
				font-size: 1rem;
				transition: color $anim-len;
				margin-bottom: -0.25rem; //font hack
				padding-bottom: 1px;
				@include desktop {
					font-size: 1.125rem;
				}
				.top {
					margin-right: 0.25rem;
				}
				.bottom {
					font-size: 1rem;
					@include tablet-landscape {
						font-size: 1.125rem;
					}
					@include tablet-landscape {
						font-weight: bold;
					}
				}
				//arrow-down
				svg {
					float: right;
					margin-right: 1rem;
					font-size: 0.75rem;
					@include tablet-landscape {
						float: unset;
						margin-right: 0;
						font-size: 1rem;
					}
				}
			}

			.dropdown {
				width: 100%;
				display: flex;
				position: relative;
				top: 4px;
				left: 0;
				right: 0;
				opacity: 1;
				background: white;
				border-bottom-right-radius: 60px;
				border-top: none;
				padding-top: 0;
				/*pointer-events: none;*/
				@include tablet-landscape {
					position: relative;
					/*border-top: 1px solid $color-lgrey;*/
					background-color: rgba(255, 255, 255, 0.9);
					top: 12px;
					padding-bottom: 0;
					border-bottom-right-radius: 200px;
				}

				.container {
					width: 1000px;
					display: none;
					flex-direction: column;
					margin: 0 auto;
					height: 0px;
					overflow: hidden;
					transition: none;
					grid-gap: 0;

					@include tablet-landscape {
						display: grid;
						grid-gap: 1rem;
						flex-direction: row;
						transition: height 0.5s, margin 0.5s;
						/*padding-top: 2rem;*/
					}

					&.open {
						display: flex;
						height: auto;
						transition: none;
						margin: auto;
						margin-bottom: 1rem;
						padding-top: 0;
						@include tablet-landscape {
							height: 280px;
							display: grid;
							transition: height 0.5s, margin 0.5s;
							/*padding-top: 2rem;*/
						}
					}
				}

				.category {
					font-size: 1rem;
					border-top: none;
					padding: 0.5rem 4px;
					padding-left: 1.25rem;
					padding-bottom: 0;
					display: flex;
					flex-direction: column;
					background-color: #f5f5f5;
					margin-top: 0;
					&:last-child {
						margin-bottom: 3rem;
					}
					@include tablet-landscape {
						border-top: 3px solid;
						padding-left: 0;
						padding-top: 1rem;
						margin-top: 2rem;
						font-size: 1.2rem;
						background-color: transparent;
					}
					&:last-child a:last-child {
						border: none;
						width: 100%;
					}
					&:last-child a:last-child:after {
						//hack =(
						display: block;
						content: "";
						position: relative;
						width: calc(100% + 25px);
						height: 1px;
						background: $color-teal;
						left: -20px;
						top: 5px;
						@include tablet-landscape {
							display: none;
						}
					}
					span {
						color: $color-wgrey;
						display: inline-block;
						font-weight: bold;
						margin-bottom: 0.25rem;
					}

					a {
						padding: 5px 0;
						border-bottom: 1px solid #e6e6e6;
						@include tablet-landscape {
							border: none;
						}
					}
				}
			}

			&:hover > a {
				color: $color-teal;
			}

			/*            &:nth-child(1):hover > a {
                color: $color-teal;
            }

            &:nth-child(2):hover > a {
                color: $color-red;
            }

            &:nth-child(3):hover > a {
                color: $color-purple;
            }

            &:nth-child(4):hover > a {
                color: $color-orange;
            }*/
		}
	}

	.mobileMenu {
		.hamburgerIcon {
			display: block;
			background: $color-dblue;
			color: white;
			width: 30px;
			height: 30px;
			font-size: 1rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			cursor: pointer;
			@include tablet-landscape {
				display: none;
			}
			.sideNav {
				position: absolute;
				top: 30px;
				background: $color-grey;
				padding: 1rem;
			}
		}
		.mobileMenuContainer {
			position: absolute;
			display: flex;
			@include tablet-landscape {
				display: none;
			}
			flex-direction: column;
			width: 100%;
			padding-bottom: 4rem;
			padding-top: 1rem;
			background: white;
			right: 0;
			border-bottom-right-radius: 60px;
			z-index: 99;

			.menuItem {
				font-size: 1rem;
				a {
					display: inline-block;
					margin: 1.2rem 0 0.15rem 1.25rem;
				}
				color: $color-dblue;
				position: relative;
				display: inline;
				border-bottom: 1px solid $color-teal;
				/*padding: 1rem 0 0.25rem 1.25rem;*/
				font-weight: bold;
			}
			.subMenu {
				display: flex;
				flex-direction: column;
				background: $color-bgrey;
				padding-top: 1rem;
				& > div:first-child {
					color: $color-wgrey;
					margin-left: 1.25rem;
					font-weight: normal;
				}
				a {
					border-bottom: 1px solid #e2e2e2;
					margin-top: 0.5rem;
				}
				&:last-child a:last-child {
					border: none;
				}
			}
		}
	}
	.icoClose {
		float: right;
		margin-right: 2rem;
		font-size: 2rem;
		margin-left: 0;
		@include phone-landscape {
			margin-left: 2rem;
		}
	}

	.searchDropdown {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		display: flex;
		align-items: center;
		background: white;
		transition: height 0.5s, margin 0.5s;
		height: 0px;
		overflow: hidden;
		z-index: 100;
		border-bottom-right-radius: 60px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		&.open {
			height: 72px;
		}
	}

	.rightMenu {
		align-self: center;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-weight: bold;
		font-size: 1.5rem;
		margin-left: unset;
		margin-bottom: 0.5rem;
		padding-top: 0;
		width: 100%;
		gap: 0.75rem;

		& > a {
			//padding-right: 1rem;
			margin-bottom: 0;
		}

		@include phone-landscape {
			width: auto;
			margin-bottom: 0;
			margin-left: auto;
		}

		@include desktop {
			font-size: 1rem;
			margin-right: 0;
			gap: 0;
		}

		nav + a {
			margin-left: 1rem;
		}

		.floatingContainer {
			margin: auto;
			max-width: 1300px;
			padding-left: 20px;
			padding-right: 20px;
			border-bottom-right-radius: 60px;
			background: white;
			width: 100%;
		}

		.searchAreaContainer {
			background: white;
			margin: auto;
			max-width: 1330px;
			background: white;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0 auto;
			.logo {
				display: none;
				margin-right: 1rem;
				@include phone-landscape {
					display: flex;
				}
			}
		}

		.topSearchForm {
			display: flex;
			flex-grow: 1;
			justify-content: flex-end;
			align-items: center;
			& > svg {
				font-size: 2rem;
				margin-right: 2rem;
			}
			input {
				font-size: 1.5rem;
				border: none;
				border-radius: 6px;
				margin-right: 2rem;
				padding-left: 0.5rem;
				padding-top: 6px; //font hack
				width: 100%;
				flex-grow: 1;
				max-width: 350px;
				@include tablet-portrait {
				}
				&:focus-visible {
					border: 2px solid black;
				}
				&::placeholder {
					color: #9b9c9d;
				}
			}
			button {
				@include button-outline($color-teal, $color-teal);
				display: none;
				text-transform: unset;
				border-top-left-radius: 100px;
				border-width: 2px;
				padding: 12px 22px 8px 22px;
				border-top-left-radius: 30px;
				border-bottom-right-radius: 30px;
				@include phone-portrait {
					display: block;
				}
				&:hover {
					background-color: $color-teal;
					color: white;
				}
			}
		}

		nav {
			cursor: pointer;
			.menuItem {
				padding: 0;
			}
			span {
				display: flex;
				justify-content: center;
			}
			.top {
				display: flex;
			}
			.bottom {
				font-weight: bold;
				svg {
					font-size: 1rem;
				}
				@include desktop {
					align-items: center;
					display: flex;
					flex-direction: column;
				}
				span {
					display: none;
					@include desktop {
						display: block;
					}
				}
			}

			.loginMenuContainer .menuItem {
				display: flex;
				flex-direction: column;
				border: none;
				.dropdown {
					display: flex;
					justify-content: flex-end;
					position: static;
					padding-right: 20px;
					background: transparent;
					max-width: 1350px;
					margin: auto;
					.content {
						position: absolute;
						left: -140px;
						@include tablet-landscape {
							left: unset;
						}
						display: flex;
						justify-content: flex-end;
						margin: 0;
						width: auto;
						height: 0;
						overflow: hidden;
						&.open {
							height: 110px;
						}
						.category {
							width: 170px;
							background: white;
							box-shadow: -3px 1px 6px 0 rgba(0, 0, 0, 0.25);
							border-bottom-right-radius: 60px;
							border: 1px solid $color-grey;
							margin: 0;
							display: block;
							align-self: flex-start;
							font-size: 0.875rem;
							color: black;
							font-weight: normal;
							padding: 1rem;
							span {
								color: $color-dblue;
							}
							span,
							button {
								font-size: 0.8rem;
								font-weight: normal;
								text-decoration: none;
							}
							a {
								display: flex;
								flex-direction: row;
								margin: 0;
								padding: 0;
								border: none;
							}
							svg {
								margin: 0;
								margin-right: 5px;
								color: $color-dblue;
								opacity: 1;
								font-size: 1rem;
							}
						}
					}
				}
			}
		}

		.faIcon {
			margin-bottom: 2px;
		}

		a {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		span {
			display: none;
			text-align: center;
			@include desktop {
				display: block;
			}
		}
	}
}
