$highlight-light: #ececec;
$highlight-dark: #002f86;

$font-light: white;
$font-dark-blue: #002f86;
$font-cyan: #009ca6;
$font-purple: #84329b;

$background-light-1: white;
$background-light-2: #f2f2f2;
$background-light-cyan: #cbe4e6;
$background-dark-cyan: #009ca6;
$background-light-purple: #ebdeef;
$background-dark-purple: #84329b;
$background-dark-blue: #002f86;
$background-dark-1: #e9e9e9;

// Colors - My CIHT colors
$color-menu-blue: #022f8a;
$color-dblue: #003087;
$color-dteal: #009ca6;
$color-teal: #039da5;
$color-lteal: #cbe4e6;
$color-red: #e03e52;
$color-orange: #ed8b00;
$color-purple: #84329b;
$color-lpurple: #f0e5f5;
$color-pink: #dd1c85;

$color-blue-alt: #496baa;
$color-lblue-alt: #e1e7f1;

//greys
$color-txt-grey: #2d2926;
$color-wgrey: #8c8279; //warm

$color-bgrey: #f2f2f2;
$color-lgrey: #ececec;
$color-grey: #c2ccdd;
$color-grey2: #dedede;
$color-dgrey: #7a7a7a;
$color-border-grey: #999999;
$color-bgr-grey: #364a5e;

.tint-dblue {
	color: $color-dblue !important;
	fill: $color-dblue !important;
}
.tint-red {
	color: $color-red !important;
	fill: $color-red !important;
}
.tint-orange {
	color: $color-orange !important;
	fill: $color-orange !important;
}
.tint-teal {
	color: $color-teal !important;
	fill: $color-teal !important;
}
.tint-purple {
	color: $color-purple !important;
	fill: $color-purple !important;
}
.tint-grey {
	color: $color-grey !important;
	fill: $color-grey !important;
}
.tint-dgrey {
	color: $color-dgrey !important;
	fill: $color-dgrey !important;
}
.tint-txt-grey {
	color: $color-txt-grey !important;
	fill: $color-txt-grey !important;
}
.tint-black {
	color: black !important;
	fill: black !important;
}
.tint-white {
	color: white !important;
	fill: white !important;
}

// Colors - KP colors

// Generic colors
$palette-black: #222020; // Black (darkest grey)
$palette-all3s: #333333; // Generic dark grey
$palette-dgrey: #4c5153; // Dark grey color
$palette-mgrey: #727272; // Medium grey color
$palette-egrey: #bdbdbc; // Earl grey :)
$palette-bgrey: #e0e0e0; // Bright grey color
$palette-lgrey: #f2f2f2; // Light grey color
$palette-nearw: #f9f8f7; // Near white color
$palette-white: #ffffff; // Full white
$palette-alert: #d6083b; // Generic alert color
$palette-warng: #f8b200; // Generic warning color
$palette-green: #77b705; // Generic green color
$palette-amber: #f8b200; // Results amber color
$palette-lgree: #88cc22; // Results light green color
$palette-dgree: #008844; // Results dark green color

// Base colors
$palette-brand-core: #240f6e; // KP Deep Blue
$palette-brand-pri1: #005de8; // KP Light Blue
$palette-brand-pri2: #082ecf; // KP Mid Blue
$palette-brand-sec1: #e5f4fa; // Light Blue
$palette-brand-sec2: #f5f8fa; // Light Blue 10%

// MyLearning colors
$palette-learning-1: #4e9d2d; // Deep Green
$palette-learning-2: #77b705; // Light Green
$palette-learning-3: #e9f3d7; // Light Green 10%

// MyCoaching colors
$palette-coaching-1: #f8b200; // Deep Yellow
$palette-coaching-2: #ffc82e; // Light Yellow

// MyResults colors
$palette-myresult-1: #e55302; // Orange

// Manager's view colors
$palette-managers-1: #d6083b; // KP Red

// Text colors
.ink-black {
	color: $palette-black;
}

.ink-all3s {
	color: $palette-all3s;
}

.ink-dgrey {
	color: $palette-dgrey;
}

.ink-mgrey {
	color: $palette-mgrey;
}

.ink-egrey {
	color: $palette-egrey;
}

.ink-bgrey {
	color: $palette-bgrey;
}

.ink-lgrey {
	color: $palette-lgrey;
}

.ink-white {
	color: $palette-white;
}

.ink-alert {
	color: $palette-alert;
}

.ink-warng {
	color: $palette-warng;
}

.ink-green {
	color: $palette-green;
}

.ink-amber {
	color: $palette-amber;
}

.ink-lgree {
	color: $palette-lgree;
}

.ink-dgree {
	color: $palette-dgree;
}

.ink-brand-core {
	color: $palette-brand-core;
}

.ink-brand-pri1 {
	color: $palette-brand-pri1;
}

.ink-brand-pri2 {
	color: $palette-brand-pri2;
}

.ink-brand-sec1 {
	color: $palette-brand-sec1;
}

.ink-brand-sec2 {
	color: $palette-brand-sec2;
}

.ink-learning-1 {
	color: $palette-learning-1;
}

.ink-learning-2 {
	color: $palette-learning-2;
}

.ink-coaching-1 {
	color: $palette-coaching-1;
}

.ink-coaching-2 {
	color: $palette-coaching-2;
}

.ink-myresult-1 {
	color: $palette-myresult-1;
}

.ink-managers-1 {
	color: $palette-managers-1;
}

// Background colors
.paint-black {
	background-color: $palette-black;
}

.paint-all3s {
	background-color: $palette-all3s;
}

.paint-dgrey {
	background-color: $palette-dgrey;
}

.paint-mgrey {
	background-color: $palette-mgrey;
}

.paint-egrey {
	background-color: $palette-egrey;
}

.paint-bgrey {
	background-color: $palette-bgrey;
}

.paint-lgrey {
	background-color: $palette-lgrey;
}

.paint-nearw {
	background-color: $palette-nearw;
}

.paint-white {
	background-color: $palette-white;
}

.paint-alert {
	background-color: $palette-alert;
}

.paint-warng {
	background-color: $palette-warng;
}

.paint-green {
	background-color: $palette-green;
}

.paint-brand-core {
	background-color: $palette-brand-core;
}

.paint-brand-pri1 {
	background-color: $palette-brand-pri1;
}

.paint-brand-pri2 {
	background-color: $palette-brand-pri2;
}

.paint-brand-sec1 {
	background-color: $palette-brand-sec1;
}

.paint-brand-sec2 {
	background-color: $palette-brand-sec2;
}

.paint-learning-1 {
	background-color: $palette-learning-1;
}

.paint-learning-2 {
	background-color: $palette-learning-2;
}

.paint-coaching-1 {
	background-color: $palette-coaching-1;
}

.paint-coaching-2 {
	background-color: $palette-coaching-2;
}

.paint-myresult-1 {
	background-color: $palette-myresult-1;
}

.paint-managers-1 {
	background-color: $palette-managers-1;
}

.paint-lgree {
	background-color: $palette-lgree;
}

.paint-dgree {
	background-color: $palette-dgree;
}

// Special paint styles

.paint-learning-comp {
	background-image: linear-gradient($palette-learning-2 50%, white 50%);
}
