@import "../../scss/colors.scss";
@import "../../scss/base.scss";

.dx-theme-generic-typography {
	font-size: 16px;
}
.dx-widget {
	font-size: 16px;
}

.dx-datagrid {
	background-color: transparent;
}
.dx-datagrid-headers {
	border-bottom: 0;
}
.dx-datagrid-table tbody tr.dx-row td:first-child,
.dx-datagrid-table tbody tr.dx-row td:last-child {
	padding-left: 2rem;
}
.dx-datagrid-table tbody tr.dx-row.dx-data-row:nth-child(odd) {
	background-color: white;
}
.dx-datagrid-table tbody tr.dx-row.dx-data-row a {
	color: $color-teal;
	text-decoration: underline;
}

.dx-datagrid .dx-row > td {
	padding-bottom: 0.6rem;
	padding-top: 1rem; /*font hack */
}

.ciht-top-search .dx-placeholder {
	margin-top: 4px;
}

.dx-texteditor-input {
	padding-top: 0.75rem; /*font hack */
	color: $color-dblue;
}

.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
	padding-top: 5px;
	padding-bottom: 5px;
}

.dx-multiview-wrapper {
	border: none !important;
}

.dx-texteditor.dx-editor-outlined {
	background: white;
}

/* CIHT simple dropdown */
.cihtDxDropdown {
	padding-left: 5px;
	margin-left: 0 !important;
	background: white url(../../../../client-app/src/assets/chevron-down.svg);
	border-color: #c2ccdd !important;
	justify-content: flex-start !important;
}
.cihtDxDropdown .dx-dropdowneditor-input-wrapper.dx-selectbox-container {
	flex-grow: 1;
}
.cihtDxDropdown .dx-placeholder {
	color: #003087;
	font-weight: bold;
	margin-top: 4px;
}
.cihtDxDropdown .dx-texteditor-container {
	background: white;
}
.cihtDxDropdown .dx-dropdowneditor-icon {
	display: none;
}
@media (min-width: 1025px) {
	.cihtDxDropdown .dx-texteditor-buttons-container {
		margin-left: auto;
	}
}
.cihtDxDropdown .dx-button-content {
	background: white url(../../../../client-app/src/assets/chevron-down.svg);
	background-repeat: no-repeat;
	background-position-y: center;
	background-position-x: 0.75rem;
}
.cihtDxDropdown .dx-texteditor-input {
	font-weight: 700;
}

/* CIHT grouped dropdown */
.cihtDxGroupDropdown {
	padding-left: 5px;
	margin-left: 0 !important;
	background: white url(../../../../client-app/src/assets/chevron-down.svg);
	border-color: #c2ccdd !important;
	justify-content: flex-start !important;
}
.cihtDxGroupDropdown .dx-dropdowneditor-input-wrapper.dx-selectbox-container {
	flex-grow: 1;
}
.cihtDxGroupDropdown .dx-placeholder {
	color: #003087;
	font-weight: bold;
	margin-top: 4px;
}
.cihtDxGroupDropdown .dx-texteditor-container {
	background: white;
}
.cihtDxGroupDropdown .dx-dropdowneditor-icon {
	display: none;
}
@media (min-width: 1025px) {
	.cihtDxGroupDropdown .dx-texteditor-buttons-container {
		margin-left: auto;
	}
}
.cihtDxGroupDropdown .dx-button-content {
	background: white url(../../../../client-app/src/assets/chevron-down.svg);
	background-repeat: no-repeat;
	background-position-y: center;
	background-position-x: 0.75rem;
}
.cihtDxGroupDropdown .dx-texteditor-input {
	font-weight: 700;
}

.cihtDxGroupDropdown.dx-state-hover {
	border-color: #c2ccdd !important;
}
.cihtDxGroupDropdown .dx-dropdowneditor-input-wrapper {
	width: 100%;
}

.dx-dropdowneditor-overlay .dx-list-item,
.dx-dropdowneditor-overlay .dx-list-group-header {
	color: #003087;
	background: white;
}

.dx-dropdowneditor-overlay .dx-list-item.dx-state-hover {
	background: #1e90ff !important;
	color: white !important;
}

.ciht-dx-checkbox-container .dx-checkbox-text {
	margin-bottom: -0.25rem;
}
.dx-checkbox.dx-state-focused .dx-checkbox-icon {
}

.ciht-dx-search-filter-list {
	.dx-checkbox-icon {
		border-top-left-radius: 0.25rem;
		border-bottom-right-radius: 0.25rem;
		width: 18px;
		height: 18px;

		.dx-checkbox-checked {
			width: 14px;
			height: 14px;
		}
	}
}
