@import "src/scss/colors";
@import "src/scss/elements/input";

%round-borders-nopad {
	border-top-left-radius: 60px;
	border-bottom-right-radius: 60px;
}
%round-borders {
	@extend %round-borders-nopad;

	//padding: 4rem 3rem 3rem 3rem;
	//GLOBAL CHANGE
	padding: 3rem;
}

%round-borders-x-sm-nopad {
	border-top-left-radius: 16px;
	border-bottom-right-radius: 16px;
}
%round-borders-x-sm {
	@extend %round-borders-x-sm-nopad;

	padding: 16px 8px 16px 8px;
}

%round-borders-sm-nopad {
	border-top-left-radius: 30px;
	border-bottom-right-radius: 30px;
}
%round-borders-sm {
	@extend %round-borders-sm-nopad;

	padding: 30px 15px 30px 15px;
}

%round-borders-lg-nopad {
	border-top-left-radius: 120px;
	border-bottom-right-radius: 120px;
}
%round-borders-lg {
	@extend %round-borders-lg-nopad;

	padding: 120px 60px 120px 60px;
}

$offset: 200px;
