$offset: 520px;
$color: #f2f2f2;

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

* {
	box-sizing: border-box;
}
.page-limit-width {
	width: 100%;
	max-width: 1260px;
	padding-left: 20px;
	padding-right: 20px;
	margin: auto;
	&.limit-narrow {
		max-width: 1000px;
	}
	&.limit-wide {
		max-width: 1400px;
	}
	&.page-shift-up {
		margin-top: -2rem;
		position: relative;
	}
}

body:has(.modal-scrollable) {
	overflow-y: hidden;
	margin-right: 17px;
}

.ciht-section-title {
	font-size: 2rem;
}

p {
	font-size: 1.125rem;
	line-height: 1.4;
	color: $color-txt-grey;
}

.ciht-bg,
.ciht-bg-inv,
.ciht-bg-inner {
	background-image: url(../assets/BG_round_left.svg), url(../assets/BG_round_right.svg),
		linear-gradient(white 0, white 100px, #f2f2f2 100px, #f2f2f2 100px);
	background-position-x: left, right, center;
	background-repeat: no-repeat;
	background-position-y: 0, 0, 0;
	background-size: 100px auto, 100px auto, auto auto;
}
.ciht-bg {
	padding-bottom: 5rem;
	padding-top: 10rem;
}
.ciht-bg-inv {
	background-image: url(../assets/BG_round_left_inv.svg), url(../assets/BG_round_right_inv.svg),
		linear-gradient(#f2f2f2 0, #f2f2f2 100px, #f2f2f2 100px, #f2f2f2 100px);
	background-position-y: calc(100% - 75px), calc(100% - 75px), -175px, 0;
}

.ciht-bg-inner {
	background-image: url(../assets/BG_round_left_inner.svg), url(../assets/BG_round_right_inner.svg),
		linear-gradient(white 0, white 100px, #f2f2f2 100px, transparent 100px);
}

.ciht-bg-btm::after {
	background-image: url(../assets/BG_round_left_inv.svg);
	background-position-y: -100px;
	bottom: -100px;
	content: "";
	display: block;
	height: 100px;
	position: absolute;
	width: 100px;
}

.react-datepicker__triangle {
	left: -50px !important;
}

button.react-datepicker__navigation:hover {
	background: none;
}

table.ciht-table {
	width: 100%;
	border-top: 1px solid #e0e0e0;
	border-collapse: collapse;

	tr:nth-child(odd) td {
		background: white;
	}
	td:nth-child(2) {
		color: $color-teal;
		font-weight: bold;
		text-decoration: underline;
	}

	td:nth-child(2) {
		width: 25%;
	}

	td:nth-child(4) {
		width: 20%;
	}

	td,
	th {
		padding: 1rem 0.5rem;
		&:first-child {
			padding-left: 2rem;
		}
		&:last-child {
			padding-right: 2rem;
		}
		&:nth-child(6) {
			text-align: right;
		}
	}
	th {
		color: $color-dgrey;
		font-weight: normal;
		text-align: left;
		/*padding-top: 2rem;*/
	}
	a {
		color: $color-teal;
		text-decoration: underline;
	}
}

/*Faulty font-face from CIHT.org*/
@font-face {
	font-family: "effra-regular";
	src: url("../assets/fonts/effra_rg.eot");
	src: url("../assets/fonts/effra_rg.woff") format("woff"), url("../assets/fonts/effra_rg.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

.topmenu-modal {
	position: static;
	@include tablet-landscape {
		position: fixed;
	}
	top: 59px;
	right: 0;
	bottom: 0;
	left: 0;
	background: #00000088;
	z-index: 99;
	height: 0;
	animation: 250ms ease-out 0s 1 fade-in;

	&.noBackground {
		background: none;
	}

	&.open {
		height: 100vh;
	}
}

/*-------------- POPUP MODAL ----------------*/

.modalWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 201;
	&.modal-scrollable {
		overflow-y: auto;

		.modalPopup {
			margin: auto;
		}
		svg {
			cursor: pointer;
		}
	}
	.header {
		display: flex;
		justify-content: flex-end;
		font-size: 2rem;
		margin-bottom: 0.5rem;

		svg {
			cursor: pointer;
		}
	}

	h1 {
		display: flex;
		flex-direction: column;
		font-size: 1.40625rem;
		margin-top: 4px; //font hack

		span {
			display: block;
			font-size: 2.3125rem;
		}

		span:first-child {
			color: $font-dark-blue;
		}

		span:last-child {
			color: $font-purple;
		}
	}
	.status-icon {
		font-size: 2rem;
		margin-right: 1rem;
		color: $color-teal;
		& + h1 {
			color: $color-teal;
		}
	}
	.icon {
		color: $color-teal;
		font-size: 2rem;
	}
	p {
		width: 93%;
		font-size: 1.375rem;
		margin: 2rem 0;
	}
	p + p {
		margin-top: 0;
	}

	button,
	.button,
	.button-outline {
		@include button-outline($color-dblue, $color-dblue);
		width: fit-content;
		background: transparent;
		text-transform: uppercase;
		&:hover {
			background: transparent;
		}
	}

	.buttonRow {
		margin-top: 1rem;
		flex-direction: column;
		@include tablet-portrait {
			flex-direction: row;
		}
		.button,
		button,
		a {
			display: flex;
			justify-content: center;
			width: 100%;
			flex: 1;
			margin-bottom: 1rem;
			margin-right: 0;
			@include tablet-portrait {
				width: auto;
				margin-bottom: 0;
				margin-right: 1rem;
			}
		}
	}

	&.inline {
		position: absolute;
		z-index: 1;

		.modalPopup {
			position: absolute !important;
		}
	}
	&.shiftDown {
		top: 15%;
	}
}

.modalPopup {
	display: flex;
	margin: 2rem;
	background: #f2f2f2;
	flex-direction: column;
	padding: 2rem 2rem 1rem;
	min-width: unset;
	max-width: 800px;
	/*min-height: 330px;*/
	box-shadow: 0 8px 16px 0 #00000029;
	@extend %round-borders-sm-nopad;
	@include phone-portrait {
		padding: 2rem 2.5rem 2rem;
	}
	@include tablet-portrait {
		min-width: 760px;
		padding: 2rem 3.5rem 3rem;
	}
	&.noData {
		background: white;
		padding-top: 3rem;
		padding-bottom: 3rem;
		& h1 {
			color: $color-teal;
			font-size: 2.3125rem;
		}
	}
	&.reduceHeight {
		padding: 2rem 3.5rem;
	}
}

.modalPopupBg {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 200;
}

///  SMART ///

.modalPopup {
	.smarties {
		display: flex;
		flex-direction: column;
		margin-top: 2rem;

		.smart-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: white;
			border-bottom-right-radius: 30px;
			border-left: 5px solid $color-dblue;
			margin-bottom: 1rem;
			min-height: 80px;
			.smart-txt {
				display: flex;
				flex-direction: column;
				justify-content: center;
				flex-grow: 1;
				min-height: 80px;
				padding: 0.5rem 0 0 1rem;
				@include phone-landscape {
					padding: 0.5rem 0 0 2rem;
				}
			}
			h2 {
				font-size: 1.5rem;
				font-weight: bold;
			}
			p {
				font-size: 1rem;
				color: $color-wgrey;
				margin: 0;
			}
			.row-cap {
				display: none;
				align-self: stretch;
				@include phone-landscape {
					display: flex;
				}
				justify-content: center;
				align-items: center;
				min-width: 80px;
				text-align: center;
				font-size: 3rem;
				font-weight: bold;
				border-bottom-right-radius: 30px;
				background: rgba($color-dblue, 0.2);
				color: rgba($color-dblue, 0.67);
				span {
					position: relative;
					top: 8px;
				}
			}
		}
		.smart-row:nth-child(1) {
			color: $color-dblue;
			border-color: $color-dblue;
			.row-cap {
				background: rgba($color-dblue, 0.2);
				color: rgba($color-dblue, 0.67);
			}
		}
		.smart-row:nth-child(2) {
			color: $color-teal;
			border-color: $color-teal;
			.row-cap {
				background: rgba($color-teal, 0.2);
				color: rgba($color-teal, 0.67);
			}
		}
		.smart-row:nth-child(3) {
			color: $color-orange;
			border-color: $color-orange;
			.row-cap {
				background: rgba($color-orange, 0.2);
				color: rgba($color-orange, 0.67);
			}
		}
		.smart-row:nth-child(4) {
			color: $color-red;
			border-color: $color-red;
			.row-cap {
				background: rgba($color-red, 0.2);
				color: rgba($color-red, 0.67);
			}
		}
		.smart-row:nth-child(5) {
			color: $color-purple;
			border-color: $color-purple;
			.row-cap {
				background: rgba($color-purple, 0.2);
				color: rgba($color-purple, 0.67);
			}
		}
	}
}

/*------------------BUTTONS------------------*/

.linkALike {
	background: none;
	padding: 0;
	border-radius: 0;
	border: none;
	text-align: center;
	color: $color-teal;
	font-weight: normal;
	text-decoration: underline;
	text-transform: none;
	cursor: pointer;
	&:hover {
		background: none;
	}
	&.disabled {
		color: $color-grey;
	}
}

.buttonRow {
	display: flex;

	flex-direction: column;
	align-items: center;
	//margin-top: 3rem;
	@include phone-portrait {
		flex-direction: row;
	}
	a,
	button {
		width: 100%;
		text-align: center;
		margin-right: 0;
		//margin-bottom: 1rem;
		@include phone-landscape {
			width: unset;
			text-align: center;
			margin-right: 1rem;
			margin-bottom: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.buttonColumn {
	display: flex;
	flex-direction: column;
	// align-items: center;
	margin-top: auto;
	/*margin-bottom: 1rem;*/

	a,
	button {
		width: fit-content;
		margin-bottom: 1.5rem;
	}
}
button.alert {
	color: $color-red !important;
	border-color: $color-red !important;
}

//ToolTip

.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	border-radius: 5px;
	bottom: 0;
	background-color: #cbe4e6;
	color: $color-teal;
	font-size: 0.75rem;
	font-weight: bold;
	letter-spacing: 0.25px;
	padding: 6px 0 2px;
	position: absolute;
	right: -75px;
	text-align: center;
	text-transform: uppercase;
	visibility: hidden;
	width: 90px;
	z-index: 1;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

//Main + Heading Containers

.mainTitleBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 0;
	margin-bottom: 3rem;
	background-color: white;
	border-bottom: none;
	@include phone-landscape {
		padding-left: 2rem;
		border-bottom: 5px solid $color-teal;
	}

	&.Introduction {
		border-color: $color-teal;
	}
	&.Intermediate {
		border-color: $color-dblue;
	}
	&.Advanced {
		border-color: $color-purple;
	}
}

.mainTitleContainer {
	@extend %round-borders-nopad;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: relative;
	top: -50px;
	background-color: rgba(255, 255, 255, 0.8);
	padding: 2rem 4rem;
	padding-bottom: calc(2rem - 8px);
	margin-bottom: 1rem;

	&.teal {
		background: rgba($color-teal, 0.8);
		color: white;
		label {
			color: white;
			font-weight: normal;
		}
	}

	&.extended {
		padding: 1.5rem 2.5rem 2.5rem;
		min-width: unset;
		@include tablet-portrait {
			min-width: 560px;
		}
	}

	@include phone-landscape {
		flex-direction: row;
		align-items: center;
		margin-bottom: 0;
	}
	h1 {
		color: $color-teal;
		font-size: 2rem;
		max-width: 200px;
		text-align: right;
		margin-right: 0;
		@include phone-landscape {
			font-size: 2.3125rem;
			margin-right: 3rem;
		}
	}

	.mainDescription {
		max-width: 240px;
		color: $color-txt-grey;
		line-height: 1.6;
		text-align: right;
		@include phone-landscape {
			text-align: left;
		}
	}
}

.headingRow {
	display: flex;
	gap: 1rem;
	align-items: flex-start;
	flex-direction: column;
	padding: 0 0.5rem;
	margin-bottom: 0;
	justify-content: space-between;
	margin-bottom: 2rem;
	@include tablet-landscape {
		align-items: center;
		margin-bottom: 3rem;
		flex-direction: row;
	}
	// Inside header (title)
	.buttonRow {
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 0;
		justify-content: center;
		width: 100%;

		@include phone-portrait {
			align-items: center;
			flex-direction: row;
		}
		@include tablet-landscape {
			align-items: flex-end;
			justify-content: flex-end;
			width: auto;
		}

		.ciht-button {
			border: 1px solid $color-dblue;
		}
	}

	&.break-never {
		flex-direction: row;
		flex-wrap: wrap;
	}

	&.break-sm {
		flex-direction: column;
		align-items: flex-start;
		@include phone-portrait {
			align-items: center;
			flex-direction: row;
		}
	}
	&.break-md {
		flex-direction: column;
		align-items: flex-start;
		@include phone-landscape {
			align-items: center;
			flex-direction: row;
		}
	}

	&.break-lg {
		flex-direction: column;
		align-items: flex-start;
		@include tablet-landscape {
			align-items: center;
			flex-direction: row;
		}
		.ciht-button {
			margin-left: 0;
			margin-top: 1rem;
			margin-right: 1rem;

			@include tablet-landscape {
				margin-left: 1rem;
				margin-right: 0;
				margin-top: 0;
			}
		}
	}

	&.gridHeading {
		gap: 1rem;
		padding-bottom: 2rem;
		margin-bottom: 0;
		border-bottom: 1px solid lightgrey;
		button,
		.button,
		.ciht-button {
			background-color: $color-dblue;
		}
	}

	h2 {
		color: $color-dblue;
	}

	.searchField {
		flex-direction: column;
		align-items: center;
		order: 1;
		width: 100%;
		justify-content: center;
		margin-bottom: 2rem;
		padding-bottom: 2rem;
		border-bottom: 1px solid lightgrey;
		@include tablet-landscape {
			order: 2;
			width: auto;
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
			justify-content: flex-start;
		}
	}

	&.courseFinder {
		flex-direction: column;
		@include tablet-landscape {
			flex-direction: row;
		}
		h2 {
			order: 2;
			@include tablet-landscape {
				order: 1;
			}
		}
		.searchField {
			flex-direction: column;
			align-items: center;
			justify-content: center;
			@include phone-landscape {
				flex-direction: row;
			}
		}
	}
}

.searchField {
	display: flex;
	flex-grow: 1;
	gap: 1.25rem;
	align-items: center;
	align-self: center;
	justify-content: center;
	margin: auto;

	@include tablet-landscape {
		margin: 0;
		justify-content: flex-end;
	}

	label {
		color: $color-teal;
		min-width: fit-content;
		font-size: 2rem;
		font-weight: bold;
		margin-bottom: -0.25rem;
	}
	.cihtInputField {
		font-size: 1.125rem;
		min-width: unset;
		@include phone-landscape {
			min-width: 360px;
		}
	}
	.ciht-button {
		min-width: 150px;
	}
}

.headingDropdowns {
	flex-wrap: wrap;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	order: 2;
	justify-content: flex-end;
	width: 100%;
	margin-top: 1rem;
	@include phone-portrait {
		flex-direction: row;
	}
	@include tablet-landscape {
		width: auto;
		margin-top: 0;
	}
	& > div {
		&:first-child {
			width: 100%;
			@include tablet-portrait {
				width: auto;
			}
		}
		//input with label
		display: flex;
		flex-grow: 1;
		align-items: center;
		justify-content: flex-end;
		@include tablet-landscape {
			flex-grow: unset;
		}
		label {
			margin-right: 1rem;
			text-align: right;
			color: $color-dblue;
		}
		#cc-area-of-interest {
			width: 240px;
		}
	}
	& > select {
		width: 240px;
	}
}

/*---*/

.cihtInputField {
	//dx
	padding-top: 7px;
	padding-bottom: 2px;
	&.dx-editor-outlined {
		border: 3px solid rgba($color-dblue, 0.25);
		&.dx-state-focused {
			border: 3px solid $color-dblue;
		}
	}
	&.small {
		border-width: 2px;
		width: unset;
		& > div > div {
			align-items: center;
		}
		@include phone-landscape {
			width: 256px;
		}
	}
}

.headingFilters {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	padding-bottom: 2rem;
	border-bottom: 1px solid lightgrey;
	@include tablet-landscape {
		padding-bottom: 0;
		border: none;
	}
	@include tablet-landscape {
		margin: unset;
		width: auto;
	}

	.cihtInputField,
	.cihtDropdown {
		background-color: $color-bgrey;
	}

	.cihtDropdown.dx-dropdowneditor {
		.dx-item-content dx-list-item-content {
		}
		.dx-texteditor-input-container {
			padding-top: 6px;
		}
	}

	label,
	.cihtInputField,
	.cihtDropdown {
		&:last-child {
			margin-right: 0px;
		}
		margin-right: 1rem;
	}

	button {
		@include button-sm($color-teal, white);
		min-width: 150px;
		margin-left: 1rem;
	}

	.icoLink {
		color: $color-teal;
		font-size: 1rem;
		font-weight: 600;
		cursor: pointer;

		span {
			margin-left: 0.5rem;
		}
	}
}

/*---------------------- CAROUSELS ----------------------*/

//react multi carousel overrides

.accessible-carousel-wrapper {
	max-width: 100%;
	position: relative;
	height: 100%;
	& > div {
		&,
		ul,
		li,
		li > div {
			height: 100%;
		}
	}
}

.react-multi-carousel-dot-list {
	bottom: -1.5rem;
}

//Carousel 'mask'
.roundedCarouselWrapper {
	position: relative;
	width: 100%;
	align-self: center;
}
.round {
	background: url("../assets/rounded.svg");
	background-repeat: no-repeat;
	width: 100px;
	height: 160px;
	position: absolute;
	z-index: 9;
	pointer-events: none;

	&.left {
		left: 0;
	}

	&.right {
		transform: scale(-1);
		right: 0;
	}
}
/*---- Carousel (or any Tile) Items ----*/

.carousel-error {
	@extend %round-borders-nopad;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.25rem;
	height: 237px;
	margin-bottom: 3rem;
	padding: 5rem 2rem;
	color: $color-wgrey;
	background: $color-bgrey;
}

.carouselBody {
	margin-bottom: 3rem;
	min-height: 265px;

	li.react-multi-carousel-item {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.carouselBookNowButton {
		@include carousel-item-button-icon($color-teal, white);
	}

	.carouselItemButton {
		@include button-round-sm($color-teal, white);
		align-items: center;
		display: flex;
		gap: 1rem;
		height: 40px;
		width: fit-content;
		// @include carousel-item-button($color-teal, white);
		// border-bottom-right-radius: 0;
		// display: flex;
		// margin-top: auto;
		// width: fit-content;

		&.completed {
			@include button-outline-sm($color-teal, white);
			color: $color-teal;
			// border-top-left-radius: 0;
			// padding: 0;
			// padding-right: 2rem;

			div {
				padding-left: 1rem;
				padding-top: 4px;
			}
			span {
				// padding-top: 3px;
			} //font hack
			.icoBox {
				height: 100%;
				display: flex;
				align-items: center;
				background: #ffffff66;
				padding: 10px;
				margin-right: 1rem;
			}
		}
	}

	.carouselItem {
		display: flex;
		flex-direction: column;
		padding: 0 0.5rem;

		h3 {
			font-size: 1rem;
			color: black;
			min-height: 2.4rem;
		}

		p {
			font-size: 0.875rem;
			line-height: 1.4;
			margin: 0.75rem 0 1rem;
			padding-right: 0.5rem;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			overflow: hidden;
		}

		a > p {
			color: $color-txt-grey;
		}

		&Thumb {
			position: relative;
			margin-bottom: 1rem;
			height: 159px;
			overflow: hidden;
			background-color: whitesmoke;

			img {
				object-position: center center;
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		&Extra {
			align-items: center;
			background: #364a5eab;
			color: $color-bgrey;
			display: flex;
			flex-direction: column;
			gap: 0.75rem;
			justify-content: center;
			padding-top: 1rem;
			position: absolute;

			right: 0;
			left: 0;
			top: 0;
			bottom: 0;

			&.free {
				background: $color-purple;
				display: flex;
				font-size: 0.825rem;
				letter-spacing: 0.25px;
				padding: 8px;
				padding-bottom: 2px;
				width: fit-content;
				height: fit-content;
				justify-self: flex-end;
				text-transform: uppercase;
				z-index: 9;
			}

			// &.completed {
			// 	background: $color-teal;
			// }
		}

		&Date {
			color: $color-wgrey;
			text-transform: uppercase;
		}
	}
}

//Login

.loginPage {
	display: flex;
	height: 100vh;
	align-items: center;
	justify-content: center;

	form {
		// display: flex;

		input {
			min-width: 280px;
			@include phone-portrait {
				min-width: 400px;
			}
		}

		div {
			display: flex;
			align-items: center;
			color: $color-teal;
			font-size: 0.8125rem;
			gap: 1.5rem;
			flex-direction: column;
			margin-top: 0.25rem;
			@include tablet-portrait {
				flex-direction: row;
			}
		}
	}
}

/*----- MISC -----*/

span[role="alert"] {
	color: $color-red;
}

.ciht-noti-wrap {
	border: 0;
	border-radius: 15px 0 15px 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	margin-bottom: 4rem;
	margin-top: -2rem;
	& > div:first-child {
		align-items: center;
		display: flex;
		background-color: #666;
		border-top-left-radius: 15px;
		color: #fff;
		padding: 1.25em 1em;
	}
	& > div:last-child {
		border-bottom-right-radius: 15px;
		color: #333;
		font-size: 1.125rem;
		padding: 1.25em 1em 1em 1em;
	}
	svg {
		opacity: 0.9;
	}
	&.alert {
		background-color: rgba($color-red, 0.16);
		& > div:first-child {
			background-color: $color-red;
		}
	}
	&.callout {
		background-color: rgba($color-orange, 0.16);
		& > div:first-child {
			background-color: $color-orange;
		}
	}
	&.info {
		background-color: rgba($color-dblue, 0.16);
		& > div:first-child {
			background-color: $color-dblue;
		}
	}
	a {
		color: $color-teal;
	}
}
.ciht-noti-wrap + .ciht-noti-wrap {
	margin-top: -2rem;
}

/*---*/
.ciht-mt-0 {
	margin-top: 0 !important;
}
.ciht-mt-1 {
	margin-top: 1rem !important;
}
.ciht-mt-2 {
	margin-top: 2rem !important;
}
.ciht-mt-3 {
	margin-top: 3rem !important;
}

.ciht-mb-1 {
	margin-bottom: 1rem !important;
}
.ciht-mb-2 {
	margin-bottom: 2rem !important;
}

.ciht-my-3 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}
.ciht-my-3 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}
