@import "./_containers.scss";

@mixin button-sm($bg-color: white, $color: black) {
	@extend %round-borders-sm-nopad;

	background-color: $bg-color;
	color: $color;
	cursor: pointer;
	border: 0;
	padding: 13px 2rem 8px 2rem; //GLOBAL CHANGE
	font-family: "effra-regular";
	font-size: 1rem;
	transition: background-color 250ms;
	&:hover {
		background-color: darken($bg-color, 5%);
	}
	&:disabled,
	&.disabled {
		color: $color-lgrey;
		border: 3px solid $color-lgrey;
		background: white;
		&:hover {
			cursor: default;
		}
	}
}

@mixin carousel-item-button-icon($bg-color: white, $color: black) {
	display: flex;

	.itemButton {
		@extend %round-borders-sm-nopad;
		align-items: center;
		justify-content: center;
		background-color: $bg-color;
		border-bottom-right-radius: 0;
		color: $color;
		display: flex;
		border: 0;
		padding: 13px 25px 6px 40px;
		font-family: "effra-regular";
		font-size: 1rem;

		transition: background-color 250ms;
	}
	.buttonIcon {
		align-items: center;
		color: $bg-color;
		background-color: rgba($bg-color, 0.4);
		border-bottom-right-radius: 30px;
		display: flex;
		padding-left: 15px;
		padding-right: 22px;
	}

	&:hover {
		.itemButton {
			background-color: darken($bg-color, 5%);
		}
		.buttonIcon {
			background-color: darken(rgba($bg-color, 0.4), 5%);
		}
	}
}

//completely round side
@mixin button-round($bg-color: white, $color: black) {
	@include button-sm($bg-color, $color);
	border-top-left-radius: 100px;
	border-bottom-right-radius: 100px;
}

@mixin button-round-sm($bg-color: white, $color: black) {
	@include button-sm($bg-color, $color);
	//padding-left: 1rem;
	//padding-right: 1rem;
	border-top-left-radius: 30px;
	border-bottom-right-radius: 30px;
	letter-spacing: 0.25px;
	// GLOBAL CHANGES!!!
}

@mixin button-round-md($bg-color: white, $color: black) {
	@include button-sm($bg-color, $color);
	border-top-left-radius: 30px;
	border-bottom-right-radius: 30px;
	font-weight: 600;
	padding: 18px 28px 13px 28px;
}

@mixin button-round-md-icon($bg-color: white, $color: black) {
	@include button-round-md($bg-color, $color);
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

@mixin button-round-sm-icon($bg-color: white, $color: black) {
	@include button-round-sm($bg-color, $color);
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

@mixin button-outline($border-color: black, $color: black) {
	@include button(white, $color);
	border: 3px solid $border-color;
	font-weight: bold;
	text-transform: uppercase;
	&:disabled,
	&.disabled {
		color: $color-grey;
		border-color: $color-grey;
		cursor: default;
		pointer-events: none;
	}
	&:hover {
		background: white;
	}
}

@mixin button-outline-sm($border-color: black, $color: black) {
	@include button-sm(white, $color);
	font-weight: bold;
	border: 3px solid $border-color;
}

@mixin button($bg-color: white, $color: black) {
	@include button-sm($bg-color, $color);

	padding: 14px 2.75rem 9px 2.75rem;
	font-size: 1rem;
}

button,
.ciht-button {
	@include button-sm($color-teal, white);
	&.invert {
		@include button-outline($color-teal, $color-teal);
	}
	&.purple {
		@include button($color-purple, $font-light);
		margin-top: auto;
		font-weight: normal;
	}
	&.completed {
		background: white;
		border: 2px solid $color-teal;
		color: $color-teal;
		&:hover {
			background: rgba($color-teal, 0.1);
		}
	}
	&.button-small {
		padding-left: 2rem;
		padding-right: 2rem;
		text-transform: none;
		min-width: unset;
	}
}

.button-outline {
	@include button-outline($color-dblue, $color-dblue);
	min-width: 180px;
	&.outline-thin {
		border-width: 1px;
	}
	&.invert {
		color: white;
		background: $color-dblue;
		border: transparent;
		&:hover {
			color: white;
			background: $color-dblue;
			border: transparent;
		}
	}
}
