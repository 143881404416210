$footer-blue: #003087;

.top-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $footer-blue;
  color: white;
  border-top-left-radius: 90px;
  padding: 3.5rem;
  line-height: 2em;
  /*margin-top: 2rem;*/


  .follow-us {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.8rem;
  }

  .social-links {
    display: flex;
    margin-bottom: 0.5rem;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      color: $footer-blue;
      background-color: white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      text-align: center;
      margin-right: 7px;
    }
  }

  .info {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .links {
    text-align: center;

    a {
      text-transform: uppercase;
      color: white;
      padding: 0 4px 0 4px;

      &:not(:last-child):after {
        content: "";
        display: inline-block;
        position: relative;
        top: 4px;
        background: white;
        width: 1px;
        height: 15px;
        margin-left: 8px;        
      }
    }
  }
}
