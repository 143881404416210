// Layout breakpoint variables
$screen-phone-xxlscreen-min: 480px;
$screen-phone-landscape-min: 640px;
$screen-tablet-portrait-min: 800px;
$screen-tablet-landscape-min: 1024px;
$screen-desktop-limit-width: 1366px;
// Multiline ellipsis clipbox
$clipbx-line-height: 1.35em;
$clipbx-line-number: 2;
// Layout block padding values
$paddin-sides-close: 1.5rem;
$paddin-sides-loose: 3rem;
$paddin-sides-wider: 5rem;
// Z-index level values
$zindex-dropdown-menu: 9;
$zindex-topmenu-stripe: 10;
$zindex-popover: 100;
// Carousel tile spacing
$carousel-tile-spacing: 10px;
$anim-len: 250ms;
/*Breakpoint mixins*/
@mixin phone-portrait {
	@media (min-width: 480px) {
		@content;
	}
}
@mixin phone-landscape {
	@media (min-width: 640px) {
		@content;
	}
}
@mixin tablet-portrait {
	@media (min-width: 800px) {
		@content;
	}
}
@mixin tablet-landscape {
	@media (min-width: 1025px) {
		@content;
	}
}
@mixin desktop {
	@media (min-width: 1200px) {
		@content;
	}
}
@mixin desktop-full {
	@media (min-width: 1440px) {
		@content;
	}
}

/*Safari letter spacing*/

_::-webkit-full-page-media,
_:future,
:root * {
	letter-spacing: -0.25px;
	-webkit-font-kerning: normal;
	-webkit-font-smoothing: antialiased;
}

/* CSS reset */
html {
	box-sizing: border-box;
	font-size: 16px;
	/*font-size: 14.222px;
    @include phone-portrait { font-size: 16px; }*/
}

*,
*:before,
*:after {
	box-sizing: inherit;
}
*:focus-visible {
	outline: none;
}

body {
	font-weight: normal;
	margin: 0;
	padding: 0;
}

h1 {
	color: $color-dblue;
	font-size: 2.3125rem;
	font-weight: normal;
	font-weight: bold;
}
h2 {
	font-size: 1.40625rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

//ol, ul { list-style: none; font-weight: normal; margin: 0; padding: 0; }
img {
	height: auto;
	max-width: 100%;
}

body,
input {
	color: $palette-black;
	font-family: "effra-regular", sans-serif;
	font-size: 1rem;
}

/*input:not([type="radio"]),*/
input:not([type="checkbox"]) {
	width: 100%;
}

button {
	cursor: pointer;
	outline: none;
}

a,
a:link,
a:visited {
	cursor: pointer;
	text-decoration: none;
}

a:active,
a:hover {
	/*color: $palette-brand-pri1;*/
}

hr {
	width: 100%;
	max-width: 1260px;
	border-top: 3px solid $color-dblue;
	opacity: 0.12;
	margin-block-start: 3em;
	margin-block-end: 3em;
}

.ciht-preloader {
	color: $color-wgrey;
	height: 100vh;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
}

.ciht-flex {
	display: flex;
}
.ciht-flex-wrap {
	display: flex;
	flex-wrap: wrap;
}
.ciht-flex-gap-xs {
	gap: 0.25rem;
}
.ciht-flex-gap-sm {
	gap: 0.5rem;
}
.ciht-flex-gap {
	gap: 1rem;
}
.ciht-flex-gap-lg {
	gap: 2rem;
}
.ciht-flex-gap-xl {
	gap: 2.5rem;
}
.ciht-flex-grow {
	display: flex;
	flex-grow: 1;
}
.ciht-flex-baseline {
	display: flex;
	align-items: baseline !important;
}
.ciht-flex-center {
	display: flex;
	align-items: center;
}
.ciht-flex-center-middle {
	display: flex;
	align-items: center;
	justify-content: center;
}
.ciht-self-center {
	align-self: center;
}
.ciht-flex-col {
	display: flex;
	flex-direction: column;
}
.ciht-flex-between {
	display: flex;
	justify-content: space-between;
}

.ciht-pos-rel {
	position: relative;
}
.ciht-w-67 {
	width: 100%;
	@include tablet-portrait() {
		width: 67%;
	}
}
.ciht-w-100 {
	width: 100%;
}

.ciht-m-0 {
	margin: 0 !important;
}
.ciht-m-1 {
	margin: 1rem !important;
}
.ciht-m-auto {
	margin: auto;
}
.ciht-ml-0 {
	margin-left: 0 !important;
}
.ciht-ml-05 {
	margin-left: 0.5rem !important;
}
.ciht-ml-1 {
	margin-left: 1rem !important;
}
.ciht-ml-2 {
	margin-left: 2rem !important;
}
.ciht-ml-auto {
	margin-left: auto;
}

.ciht-mr-05 {
	margin-right: 0.5rem !important;
}
.ciht-mr-1 {
	margin-right: 1rem !important;
}
.ciht-mr-15 {
	margin-right: 1.5rem !important;
}
.ciht-mr-2 {
	margin-right: 2rem !important;
}
.ciht-mr-25 {
	margin-right: 2.5rem !important;
}
.ciht-mr-3 {
	margin-right: 3rem !important;
}
.ciht-my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.ciht-my-1 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}
.ciht-my-2 {
	margin-top: 2rem !important;
	margin-bottom: 2rem !important;
}
.ciht-my-3 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}
.ciht-my-4 {
	margin-top: 4rem !important;
	margin-bottom: 4rem !important;
}
.ciht-my-5 {
	margin-top: 5rem !important;
	margin-bottom: 5rem !important;
}

.ciht-mt-n-15 {
	margin-top: -1.5rem !important;
}

.ciht-mt-05 {
	margin-top: 0.5rem !important;
}
.ciht-mt--1 {
	margin-top: -1rem !important;
}
.cith-mt--2 {
	margin-top: -2rem !important;
}
.ciht-mt-3 {
	margin-top: 3rem !important;
}
.ciht-mt-4 {
	margin-top: 4rem !important;
}
.ciht-mt-5 {
	margin-top: 5rem !important;
}

.ciht-mb-0 {
	margin-bottom: 0 !important;
}
.ciht-mb-05 {
	margin-bottom: 0.5rem !important;
}
.ciht-mb-3 {
	margin-bottom: 3rem !important;
}
.ciht-mb-4 {
	margin-bottom: 4rem !important;
}
.ciht-mb-5 {
	margin-bottom: 5rem !important;
}

.ciht-p-1 {
	padding: 1rem !important;
}
.ciht-py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.ciht-px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.ciht-px-2 {
	padding-left: 2rem !important;
	padding-right: 2rem !important;
}
.ciht-pl-0 {
	padding-left: 0 !important;
}
.ciht-pl-05 {
	padding-left: 0.5rem !important;
}
.ciht-pb-2 {
	padding-bottom: 2rem !important;
}
.ciht-pt-1 {
	padding-top: 1rem !important;
}
.ciht-pt-2 {
	padding-top: 2rem !important;
}
.ciht-pt-4 {
	padding-top: 4rem !important;
}
.ciht-p-1 {
	padding: 1rem !important;
}

.hide-xl {
	display: inline;
	@include desktop {
		display: none;
	}
}
.hide-lg {
	display: inline;
	@include tablet-landscape {
		display: none;
	}
}
.hide-md {
	display: inline;
	@include tablet-portrait {
		display: none;
	}
}

.hide-sm {
	display: none;
	@include phone-landscape {
		display: inherit;
	}
}

.show-sm {
	display: inherit;
	@include phone-landscape {
		display: none;
	}
}

.show-md {
	display: none;
	@include tablet-portrait {
		display: inline;
	}
}
.show-lg {
	display: none;
	@include tablet-portrait {
		display: inline;
	}
}

.vspace {
	&00 {
		display: block;
		height: 0;
	}
	&05 {
		display: block;
		height: 0.5em;
	}
	&10 {
		display: block;
		height: 1em;
	}
	&15 {
		display: block;
		height: 1.5em;
	}
	&20 {
		display: block;
		height: 2em;
	}
	&30 {
		display: block;
		height: 3em;
	}
	&40 {
		display: block;
		height: 4em;
	}
	&50 {
		display: block;
		height: 5em;
	}
	&80 {
		height: 8em;
	}
	&100 {
		display: block;
		height: 10em;
	}
}

.fa-blank {
	visibility: hidden !important;
}
