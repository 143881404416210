$offset: 520px;
$color: #f2f2f2;

.protectedPageWrapper {
    background: white;
    font-family: 'effra-regular'; font-size: 16px;
    width: 100%;

    main {
       margin-top: 4rem;
    }

}